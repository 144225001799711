$(document).ready(function() {
  $('#employee_search').on('blur', function(){
    $('#employee_search_form').submit()
  })
  $('.employee_row').on('click', function(){
    var value = $(this).val()
    var ids = $('#selected_ids').val().split(',')
    if($(this).is(':checked')){
      if(!ids.includes(value.toString())){
        ids.push(value.toString())
        $('.hidden_field_selections').append("<input type='hidden' value='"+ value.toString() +"' name='payroll[employee_ids][]' id='payroll_employee_"+ value.toString()+"'>")
      }
    }else{
      if(ids.includes(value.toString())){
        ids = ids.filter((id) => id !== value.toString())
        $("#payroll_employee_"+value.toString()).remove()
      }
    }
    $('#selected_ids').val(ids.filter(Boolean).join(","))
    updatePagination(ids.filter(Boolean).join(","))
  })

  $('#selected_employee_all').on('click', function(){
    var count = 0
    if($(this).is(':checked')){
      $('.employee_row').each(function() {
        if(!$(this).is(':checked')){
          $(this).click();
        }
        count++
      })
    }else{
      $('.employee_row').each(function() {
        if($(this).is(':checked')){
          $(this).click();
        }
      })
      count = 0
    }
    console.log(count)
    $('#payroll_selected_emp_count').text(count)
  })

  $('.employee_row').on('click', function(){
    var count = 0
    $('.employee_row').each(function() {
      if($(this).is(':checked')){
        count++
      }
    })
    $('#payroll_selected_emp_count').text(count)
  });

  function updatePagination(selectedIds){
    $('.pagination a').each(function() {
      var href = $(this).attr('href');
      if (href) {
          var url = new URL(href, window.location.origin);
          if (url.searchParams.has('selected_ids')) {
              url.searchParams.set('selected_ids', selectedIds);
          } else {
              url.searchParams.append('selected_ids', selectedIds);
          }
          $(this).attr('href', url.toString());
      }
    });
  }
})